// extracted by mini-css-extract-plugin
export var root = "project-module--root--+-3Dc";
export var title = "project-module--title--CAXQB typography-module--responsiveTitle1--0t0jA";
export var mainImage = "project-module--mainImage--vGvrQ";
export var grid = "project-module--grid--RTc5P";
export var mainContent = "project-module--mainContent--W01Uw";
export var metaContent = "project-module--metaContent--O+2BQ";
export var publishedAt = "project-module--publishedAt--po0C2 typography-module--small--KHf9L";
export var categories = "project-module--categories--nV++O";
export var categoriesHeadline = "project-module--categoriesHeadline--jC5p1 typography-module--base--FphVn";
export var relatedProjects = "project-module--relatedProjects--CX5M0";
export var relatedProjectsHeadline = "project-module--relatedProjectsHeadline--mpdAZ typography-module--base--FphVn";